body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.uppy-Dashboard-inner {
  /*margin-top: 40%;*/
  position: fixed;
  top:70%;
  margin: auto;
  max-width: 100%; /* no !important */
  max-height: 70% !important;
  border-radius: 5px;
}
.uppy-Dashboard--modal .uppy-Dashboard-inner{
  /*top: 50% !important;*/
  margin: auto;
}
.ril__outer {
  background-color: rgba(0, 0, 0, 0.85);
  outline: none;
  top: 34px !important;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}
